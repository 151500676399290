var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"container"}},[_c('div',{style:({
      'background-color': _vm.environment.theme.pictoColor,
      top: _vm.squarePosition.top,
      bottom: _vm.squarePosition.bottom,
      left: _vm.squarePosition.left,
      right: _vm.squarePosition.right,
    }),attrs:{"id":"square"}}),_c('img',{attrs:{"alt":_vm.alt,"src":_vm.src}})])
}
var staticRenderFns = []

export { render, staticRenderFns }