import Vue from 'vue'
import Vuex from 'vuex'

// Example Apps
import CartModule from './modules/cart/store'
import EnvironmentModule from './modules/environment/store'
import InsuranceModule from './modules/insurance/store'
import FluxModule from './modules/flux/store'

Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
    modules: {
        'cart-app': CartModule,
        'environment-app': EnvironmentModule,
        'insurance-app': InsuranceModule,
        'flux-app': FluxModule,
    }
})

export default store
