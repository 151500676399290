/*
|---------------------------------------------------------------------
| Cart Vuex Mutations
|---------------------------------------------------------------------
|
| Synchronous operations for board store
|
*/

const resetPromoCode = (state) => {
    state.cards.infoCode = {}
}

const resetFunnelData = (state) => {
    resetPromoCode(state)

    state.cards = {
        ...state.cards,
        shippingInfo: null,
        totalWithPort: null,
        totalWithPortR: null,
    }
}

export default {
    addIntoCart: (state, card) => {
        state.cards.push(Object.assign({}, card))
    },
    addProduct: (state, value) => {
        let objIndex = state.products.findIndex((obj => obj.id == value.id));
        if (objIndex > -1) {
            state.products.splice(objIndex, 1, value)
        } else {
            state.products.push(value)
        }

        resetFunnelData(state)

        window.parent.postMessage({event: 'items-count', count: state.products.length}, '*');
    },
    editProduct: (state, value) => {
        let objIndex = state.products.findIndex((obj => obj.id == value.id));
        if (objIndex > -1) {
            state.products.splice(objIndex, 1, value)
        } else {
            state.products.push(value)
        }

        resetFunnelData(state)
    },
    afterDelete(state) {
        resetFunnelData(state)
        window.parent.postMessage({event: 'items-count', count: state.products.length}, '*');
    },
    getTotalCart: (state, products) => {
        state.cards.totalTTC = 0
        state.cards.totalBonus = 0

        for (let i = 0; i < products.length; i++) {
            for (let j = 0; j < products[i].reparations.length; j++) {
                const repair = products[i].reparations[j]
                state.cards.totalTTC = state.cards.totalTTC + (repair.qte * repair.price)
                state.cards.totalBonus += repair.qte * (repair.bonus ?? 0)
            }
        }

        state.cards.totalTTC = Math.round((state.cards.totalTTC - state.cards.totalBonus) * 100) / 100
    },
    setTotalWithPort(state, value) {
        state.cards.totalWithPort = value
    },
    setTotalRemise(state, value) {
        state.cards = {...state.cards, totalWithPortR: value}
    },
    setShippingInfo(state, value) {
        resetPromoCode(state)

        state.cards = {...state.cards, shippingInfo: value}
    },
    setOrderId(state, value) {
        state.cards = {...state.cards, orderId: value}
    },
    setInfoCode(state, value) {
        state.cards = {...state.cards, infoCode: value}
    },
    toggleCgvCguAccepted(state) {
        state.cgvCguAccepted = !state.cgvCguAccepted
    },
    setInsurance(state, value) {
        const oldInsurancePrice = state.cards.insurance?.price ?? 0

        state.cards.insurance = value
        state.cards.totalWithPort = state.cards.totalWithPort + parseFloat(value?.price ?? 0) - oldInsurancePrice
    }
}
