export default {
    totalWithPort: null,
    totalWithPortR: null,
    insurance: null,
    customerInfo: {
        id: null,
        name: null,
        first_name: null,
        email: null,
        tel: null,
        adr1: null,
        adr2: null,
        zip_code: null,
        city: null,
        newsletter_subscribed: false,
        country_id: 1,
        country_code: 'FR',
    },
    infoCode: {}
}
